/* global googletag, gptadslots */
import { dfpManager } from '../../../base/js/dfp/dfp-manager';


googletag.cmd.push(() => {
  // Full-page banner
  const mapping1 = googletag.sizeMapping()
    .addSize([1024, 0], [[728, 90], [970, 90]])
    .addSize([768, 0], [728, 90])
    .addSize([0, 0], [[320, 50], [320, 100]])
    .build();

  // The above-the-header banner (LB1)
  const mappingHeader = googletag.sizeMapping()
    .addSize([1024, 0], [[728, 90], [970, 90]])
    .addSize([0, 0], [])
    .build();

  // For 160x600
  const mapping2 = googletag.sizeMapping()
    .addSize([1024, 0], [[160, 600], [300, 600]])
    .addSize([0, 0], [[336, 280], [300, 250]])
    .build();

  // For 300x250 MPU4
  const mapping3 = googletag.sizeMapping()
    .addSize([1024, 0], [])
    .addSize([0, 0], [300, 250])
    .build();

  const mapping4 = googletag.sizeMapping()
    .addSize([1220, 0], [728, 90])
    .addSize([1024, 0], [])
    .addSize([768, 0], [728, 90])
    .addSize([0, 0], [[320, 50], [320, 100]])
    .build();

  // Adslot 1 declaration
  gptadslots[1] = googletag.defineSlot(
    '/55877742/Corrections1',
    [[728, 90]],
    'div-gpt-ad-643255121587703821-1',
  ).setTargeting('pos', ['LB1']).defineSizeMapping(mappingHeader)
    .addService(googletag.pubads());

  // Adslot 4 declaration
  gptadslots[4] = googletag.defineSlot(
    '/55877742/Corrections1',
    [[160, 600]],
    'div-gpt-ad-643255121587703821-4',
  ).setTargeting('pos', ['WSKY1']).defineSizeMapping(mapping2)
    .addService(googletag.pubads());

  // Adslot 6 declaration
  gptadslots[6] = googletag.defineSlot(
    '/55877742/Corrections1',
    [[300, 250]],
    'div-gpt-ad-643255121587703821-6',
  ).setTargeting('pos', ['MPU2']).addService(googletag.pubads());

  // A regular LB2 - a 728px ad in the content area
  gptadslots[8] = googletag.defineSlot(
    '/55877742/Corrections1',
    [[728, 90]],
    'div-gpt-ad-643255121587703821-8',
  ).setTargeting('pos', ['LB2']).defineSizeMapping(mapping4)
    .addService(googletag.pubads());

  // Adslot 11 declaration
  gptadslots[11] = googletag.defineSlot(
    '/55877742/Corrections1_Skin',
    [[1, 1]],
    'div-gpt-ad-643255121587703821-11',
  ).addService(googletag.pubads());

  // Adslot 13 declaration
  gptadslots[13] = googletag.defineSlot(
    '/55877742/Corrections1',
    [[300, 90]],
    'div-gpt-ad-643255121587703821-13',
  ).addService(googletag.pubads());

  // Adslot 14 declaration
  gptadslots[14] = googletag.defineSlot(
    '/55877742/Corrections1',
    [[300, 250]],
    'div-gpt-ad-643255121587703821-14',
  ).setTargeting('pos', ['MPU3']).addService(googletag.pubads());

  // Adslot 15 declaration
  gptadslots[15] = googletag.defineSlot(
    '/55877742/Corrections1',
    [[728, 90]],
    'div-gpt-ad-643255121587703821-15',
  ).setTargeting('pos', ['LB3']).defineSizeMapping(mapping4)
    .addService(googletag.pubads());

  // Adslot 18 declaration - Out of Page
  gptadslots[18] = googletag.defineOutOfPageSlot(
    '/55877742/Corrections1_Adhesion',
    'div-gpt-ad-643255121587703821-18',
  ).addService(googletag.pubads());

  // Adslot 19 declaration
  gptadslots[19] = googletag.defineSlot(
    '/55877742/Corrections1',
    [[300, 250]],
    'div-gpt-ad-643255121587703821-19',
  ).setTargeting('pos', ['MPU4']).defineSizeMapping(mapping3)
    .addService(googletag.pubads());

  gptadslots[20] = googletag.defineSlot(
    '/55877742/Corrections1_Sponsored_Article',
    [[728, 90]],
    'div-gpt-ad-643255121587703821-20',
  ).setTargeting('pos', ['SponsoredLB']).defineSizeMapping(mapping4)
    .addService(googletag.pubads());

  gptadslots[21] = googletag.defineSlot(
    '/55877742/Corrections1_Incontent',
    [[300, 250]],
    'div-gpt-ad-643255121587703821-21',
  ).setTargeting('pos', ['IncontentMPU']).addService(googletag.pubads());

  // A sticky LB2, it spans both the content and the sidebar
  gptadslots[29] = googletag.defineSlot(
    '/55877742/Corrections1',
    [[728, 90]],
    'div-gpt-ad-643255121587703821-29',
  ).setTargeting('pos', ['LB2']).defineSizeMapping(mapping1)
    .addService(googletag.pubads());

  dfpManager.setTargets(googletag);
  dfpManager.enable();
});
